import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style.css"
import "../components/base.css"
import {ChevronRight, MaterialHelp} from "../components/material-icons";

const DigitalBadgePage = () => (<Layout>

    <section className={"section--lowspace section--row hcenter"}>
        <h1 className={"supertitle text-center"}>OpenBadge Compliant <br/>Digital Badges</h1>
        <p className={"text-center"}>Whether you’re recognizing an achievement, illustrating skill accumulation, or<br/>
            incentivizing behavior, Accredible makes designing and awarding digital badges easy and intuitive.</p>
        <a className={"button--icon"} href={"/?signup"}>
            Start Now, It's Free
            <ChevronRight color={"#FFF"} size={"20px"}/>
        </a>
    </section>


    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

</Layout>)


export const Head = () => <Seo title="Digital Badge - Hyperstack"/>

export default DigitalBadgePage
